import { ready } from "~/js/lib/utils";
ready(function () {
  // When the registration type is changed, switch the text on the frontpage.
  var employer = document.querySelector(".IndexHero__employer");
  var employee = document.querySelector(".IndexHero__employee");
  document.querySelector("#registertype-employee").addEventListener("change", function () {
    employer.classList.remove("IndexHero--visible");
    employee.classList.add("IndexHero--visible");
  }, false);
  document.querySelector("#registertype-employer").addEventListener("change", function () {
    employer.classList.add("IndexHero--visible");
    employee.classList.remove("IndexHero--visible");
  }, false);
});