/**
 * Executes fn after ms milliseconds. Each time the function is called, the
 * timer is reset.
 *
 * @param {Function} fn - The function to be called
 * @param {number} ms - The amount of milliseconds after which the function
 *                      will be called
 * @param {any?} thisArg - The function will be called with this argument as
 *                         `this` if given.
 * @param {any[]} params - Any extra parameters passed in to be passed to the
 *                         function.
 */
export var debounce = function debounce(fn, ms, thisArg) {
  for (var _len = arguments.length, params = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
    params[_key - 3] = arguments[_key];
  }

  if (fn.timeoutID) {
    window.clearTimeout(fn.timeoutID);
  }

  fn.timeoutID = window.setTimeout(function () {
    fn.apply(thisArg, params);
    delete fn.timeoutID;
  }, ms);
};
/**
 * Queues a function to be executed when the DOM is ready.
 *
 * @param {() => void} fn - The function to be executed
 * @param {any} [thisArg=null] - The object to bind as "this" to the function
 * @param {any[]} ...params - The parameters to be passed to the function
 */

export var ready = function ready(fn) {
  var thisArg = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

  for (var _len2 = arguments.length, params = new Array(_len2 > 2 ? _len2 - 2 : 0), _key2 = 2; _key2 < _len2; _key2++) {
    params[_key2 - 2] = arguments[_key2];
  }

  document.addEventListener("DOMContentLoaded", function () {
    return fn.apply(thisArg, params);
  }, false);
};